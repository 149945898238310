import React, { useEffect, useState } from 'react';
import img from '@/assets/new-logo.png';
import flag1 from '@/assets/flag1.jpg';
import flag2 from '@/assets/flag2.jpg';
import './header.css';
import UserProfile from './UserProfile/UserProfile';
import MenuLinks from './MenuLinks/MenuLinks';
import { Link } from 'react-router-dom';
import AddList from './AddList/AddList';
import { Button } from '@/components/Elements/Button';
import { useAuth } from '@/lib/auth';
import storage from '@/utils/storage';
import { ConfirmDialogWithButton } from '@/components/Elements/ConfirmationDialog/ConfirmDialogWithButton';
import { usePersonStore } from '@/stores/currencyConverter';
import { useCurrencyListingUser } from '@/features/admin/api/getCurrencyListingUser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import i18next from 'i18next';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const localCurrency = storage.getCurrency();

export enum HeaderType {
  LANDING = 'landing',
  DASHBOARD = 'dashboard',
  OURSERVICE = 'ourservice',
}

const loginHeader = localStorage.getItem('loginType');
const adminToken = localStorage.getItem('adminToken');

const Header = ({ type }: { type: HeaderType }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { updateCurrency, setCurrencies } = usePersonStore();

  const lang = localStorage.getItem('lang') || 'no';

  const handleLangNorwegian = () => {
    localStorage.setItem('lang', 'no');
    window.location.reload();
  };

  const [currencyData, setCurrencyData] = useState();
  const handleLangEnglish = () => {
    localStorage.setItem('lang', 'en');
    window.location.reload();
  };

  const { data } = useCurrencyListingUser({ page: '1' });
  const myID = data?.data[0]?.id;

  const [curr, setCurr] = useState('');

  useEffect(() => {
    if (myID) {
      if (curr == '') {
        const localAmount = storage.getAmount();
        if (!localAmount) {
          updateCurrency(myID);
          storage.setAmount(data?.data[0]?.converted_price);
          storage.setSymbol(data?.data[0]?.symbol);
        }
      }
    }
  }, [myID]);

  const handleCurr = (val: any) => {
    console.log(currencyData, 'ppppppppppppcurrencyDatappppppppppp');
    setCurr(val);
    handleOpen();
  };

  useEffect(() => {
    if (data?.data) {
      setCurrencyData(data?.data);
      setCurrencies(data?.data);
    }
  }, [data]);

  const auth = useAuth();
  const handleSwithAdmin = () => {
    storage.clearToken();
    storage.setToken(adminToken);
    location.href = '/admin/property-requests';
    localStorage.removeItem('loginType');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('adminToken');
  };

  const handleSwithAgency = () => {
    storage.clearToken();
    storage.setToken(adminToken);
    location.href = '/agency/property-requests';
    localStorage.removeItem('loginType');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('adminToken');
  };
  const name = auth?.user?.first_name;
  const lastName = auth?.user?.last_name;

  return (
    <>
      <header>
        {loginHeader === 'admin' && (
          <div>
            <div className="AgentHeader centerAlignButton">
              <p>
                {i18next.t('Login as')}
                <span className="htopNames">
                  {name} {lastName}
                </span>
                <ConfirmDialogWithButton
                  triggerButton={<button> {i18next.t('Switch back to admin')}</button>}
                  confirmButton={
                    <Button onClick={handleSwithAdmin} variant="primary">
                      {i18next.t('Yes, Login')}
                    </Button>
                  }
                  title="Confirm"
                  icon="info"
                  body="Are you sure you want to Switch back to admin ?"
                />
              </p>
            </div>
          </div>
        )}
        {loginHeader === 'agency' && (
          <div>
            <div className="AgentHeader centerAlignButton">
              <p>
                {i18next.t('Login as')}
                <span className="htopNames">
                  {name} {lastName}
                </span>
                <ConfirmDialogWithButton
                  triggerButton={<button>{i18next.t('Switch back to agency')}</button>}
                  confirmButton={
                    <Button onClick={handleSwithAgency} variant="primary">
                      {i18next.t('Yes, Login')}
                    </Button>
                  }
                  title="Confirm"
                  icon="info"
                  body="Are you sure you want to Switch back to agency ?"
                />
              </p>
            </div>
          </div>
        )}
        <nav className="navbar navbar-expand-lg bg-dark user-nav navigation-main p-1 d-none">
          <div className="container">
            <div className="row col-md-12 hellohaer">
              <div className="col-md-10"></div>
              <div className="col-md-1">
                {lang === 'no' ? (
                  <button onClick={handleLangEnglish} title="Norwegian">
                    <img className="lang-flags" src={flag1} alt="Norweig Flag" />
                  </button>
                ) : (
                  <button value="en" onClick={handleLangNorwegian} title="English">
                    <img className="lang-flags" src={flag2} alt="Uk Flag" />
                  </button>
                )}
              </div>
              <div className="col-md-1">
                {currencyData && (
                  <>
                    <select
                      className="form-select currencyDrop"
                      aria-label="Default select example"
                      onChange={(e) => handleCurr(e.currentTarget.value)}
                      defaultValue={localCurrency}
                      value={localCurrency}
                    >
                      {currencyData.map((item: any, index: any) => (
                        <option key={index} value={item.id}>
                          {item.symbol}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg  navigation-main user-bar">
          <div className="container header-navs">
            <Link className="navbar-brand" to="/">
              <img src={img} alt="logo" />
            </Link>
            {/* {type === HeaderType.LANDING && <MenuLinks />} */}
            <div className="button-dash-content d-flex align-items-center ">
              <AddList />
              <UserProfile />
            </div>
          </div>
        </nav>
      </header>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <span className="modTitleSpan">{i18next.t('Confirmation')}</span>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {i18next.t(
                `Are you sure you want to change currency , will lead to refresh page data!`
              )}
              <div className="row mt-4">
                <div className="col-md-6"></div>
                <div className="col-md-6 mkdCr">
                  <Button variant="outline" onClick={() => handleClose()}>
                    {i18next.t(`Cancel`)}
                  </Button>

                  <Button onClick={() => updateCurrency(curr)}>{i18next.t('Yes')}</Button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Header;
