import { lazyImport } from '@/utils/lazyImport';
import { MainLayout, SideNavigationItem } from '@/components/Layout';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { HomeIcon, UserIcon, FingerPrintIcon, ClipboardListIcon } from '@heroicons/react/outline';
import AdminLoader from '@/components/Elements/Loader/AdminLoader';
// import { StripePayment } from '@/components/Agency_payment/Stripe/StripePayment';
// import { SubscriptionType } from './routes/SubscriptionAgency/SubscriptionType';
// import SubscriptionType } from './routes/SubscriptionAgency/SubscriptionType';
// import { TeamAgencys } from './routes/TeamAgency/TeamAgencys';
// import { AgencyProfile } from './auth/routes/AgencyProfile';
// import { AgencyLeads } from './routes/Leads/AgencyLeads';
// import { SubScriptionPayment } from './routes/SubscriptionAgency/SubScriptionPayment';
// import { AgencyChangePassword } from './auth/routes/AgencyResetPassword';
// import { MyRatings } from './routes/MyRatings/MyRatings';
// import { AgencyDashboard } from './routes/AgencyDashboard';

const { StripePayment } = lazyImport(
  () => import('@/components/Agency_payment/Stripe/StripePayment'),
  'StripePayment'
);
const { SubscriptionType } = lazyImport(
  () => import('./routes/SubscriptionAgency/SubscriptionType'),
  'SubscriptionType'
);

const { TeamAgencys } = lazyImport(() => import('./routes/TeamAgency/TeamAgencys'), 'TeamAgencys');
const { AgencyProfile } = lazyImport(() => import('./auth/routes/AgencyProfile'), 'AgencyProfile');
const { AgencyLeads } = lazyImport(() => import('./routes/Leads/AgencyLeads'), 'AgencyLeads');
const { SubScriptionPayment } = lazyImport(
  () => import('./routes/SubscriptionAgency/SubScriptionPayment'),
  'SubScriptionPayment'
);
const { AgencyChangePassword } = lazyImport(
  () => import('./auth/routes/AgencyResetPassword'),
  'AgencyChangePassword'
);
const { MyRatings } = lazyImport(() => import('./routes/MyRatings/MyRatings'), 'MyRatings');

const { AgencyDashboard } = lazyImport(() => import('@/features/agency/routes'), 'AgencyDashboard');
const { AllNotifiationsAgency } = lazyImport(
  () => import('@/features/agency/routes/NotificationAgency/AllNotifiationsAgency'),
  'AllNotifiationsAgency'
);

const { Inquiries } = lazyImport(() => import('@/features/agency/routes'), 'Inquiries');

export const publicRoutes = [];
export const protectedRoutes = [];

const App = () => {
  const navigation = [
    { name: 'Dashboard', to: './agency-dashboard', icon: HomeIcon },
    { name: 'Profile', to: './profile', icon: UserIcon },
    { name: 'Inquiries', to: './inquiries', icon: ClipboardListIcon },
    { name: 'Property Requests', to: './property-requests', icon: UserIcon },
    { name: 'Leads', to: './leads', icon: UserIcon },
    { name: 'Subscription', to: './subscription', icon: UserIcon },
    { name: 'Ratings & Reviews', to: './ratings-reviews', icon: UserIcon },
    { name: 'Change Password', to: './reset-password', icon: FingerPrintIcon },

    // { name: 'Dashboard', to: '.', icon: HomeIcon },
    // { name: 'Report', to: './admin-report', icon: HomeIcon },
    // { name: 'General Settings', to: './general-settings', icon: HomeIcon },
  ];

  navigation.filter(Boolean) as SideNavigationItem[];

  return (
    <MainLayout navigation={navigation}>
      <Suspense
        fallback={
          <div className="h-full w-full">
            <AdminLoader />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
export const agencyRoutes = [
  {
    path: '/agency',
    element: <App />,
    children: [
      { path: '', element: <AgencyProfile /> },
      { path: 'property-requests', element: <TeamAgencys /> },
      { path: 'profile', element: <AgencyProfile /> },
      { path: 'inquiries', element: <Inquiries /> },
      { path: 'reset-password', element: <AgencyChangePassword /> },
      { path: '*', element: <Navigate to="." /> },
      { path: 'agency-notification', element: <AllNotifiationsAgency /> },

      { path: 'subscription-type', element: <SubscriptionType /> },
      { path: 'subscription-select-payment/:id', element: <SubScriptionPayment /> },
      { path: 'subscription-payment/:id', element: <StripePayment /> },
      { path: 'leads', element: <AgencyLeads /> },
      { path: 'subscription', element: <SubscriptionType /> },
      { path: 'agency-dashboard', element: <AgencyDashboard /> },
      { path: 'ratings-reviews', element: <MyRatings /> },

      // { path: 'admin-report', element: <AdminReport /> },
      // { path: 'general-settings', element: <GeneralSetting /> },
      // { path: 'users/:id', element: <User /> },
      // { path: 'edit-item/:id', element: <EditUser /> },
      // { path: 'inquiry/:id', element: <Inquiry /> },
      // { path: 'completed-deals-listings', element: <MyDealsAdminList /> },
      // { path: 'completed-deals-details/:id', element: <MyDealsAdmin /> },
      // { path: 'completed-deals-details', element: <MyDealsAdmin /> },
    ],
  },
];
