import { Navigate, Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import { lazyImport } from '@/utils/lazyImport';
import './user.css';
import logo from '@/assets/new-logo.png';
// import { LinkNotFound } from './routes/LinkNotFound';
// import { SubscriptionType } from './SubscriptionType/SubscriptionType';
// import { ChargePayment } from '@/components/Payments/StripeCharge/ChargePayment';
// import { GetVerified } from './components/GetVerified';
// import { GetVerifiedOtp } from './components/GetVerifiedOtp';
// import { Rating } from './routes/Reviews/Rating';
const { LinkNotFound } = lazyImport(() => import('./routes/LinkNotFound'), 'LinkNotFound');
const { SubscriptionType } = lazyImport(
  () => import('./SubscriptionType/SubscriptionType'),
  'SubscriptionType'
);
const { ChargePayment } = lazyImport(
  () => import('@/components/Payments/StripeCharge/ChargePayment'),
  'ChargePayment'
);
const { GetVerified } = lazyImport(() => import('./components/GetVerified'), 'GetVerified');
const { GetVerifiedOtp } = lazyImport(
  () => import('./components/GetVerifiedOtp'),
  'GetVerifiedOtp'
);
const { Rating } = lazyImport(() => import('./routes/Reviews/Rating'), 'Rating');
// import { MyDeal } from './routes/MyDeal/MyDeal';
// import { Contracts } from './routes/Contract/Contracts';
// import { ContractSignin } from './routes/Contract/ContractSignin';
// import { PaymentContract } from './routes/Contract/PaymentContract';
// import { Purchase } from './routes/Contract/Purchase';
// import { MyLeads } from './routes/MyLeads/MyLeads';
// import { PropertyOption } from './routes/PropertySaleRent/PropertyOption';
// import { ReeipoServices } from './routes/ReeipoServices/ReeipoServices';
// import { AddPropertyRent } from './routes/PropertySaleRent/AddPropertyRent';
// import { CompanyInvestment } from './routes/Investor/CompanyInvestment';
// import { OfflinePayment } from './routes/Contract/OfflinePayment';
// import { VerifyContract } from './routes/Contract/VerifyContract';
// import { DraftListing } from './routes/DraftListing';
// import { PaymentDone } from './routes/PaymentDone/PaymentDone';
// import { SavedSearch } from './routes/SavedSearch';
// import { BusinessMeetingSent } from './routes/BusinessMetingSent/BusinessMeetingSent';
// import { AgencyMeeting } from './routes/AgencyMeeting/AgencyMeeting';
// import { SalePaymentContract } from '../misc/routes/SaleDetail/SalePayment/SalePaymentContract';
// import { SaleOfflinePayment } from '../misc/routes/SaleDetail/SalePayment/SaleOfflinePayment';
// import { SaleVerifyContract } from '../misc/routes/SaleDetail/SalePayment/SaleVerifyContract';
// import { SubscriptionPayment } from './SubscriptionType/SubscriptionPayment';
// import { BuySharesDetail } from './routes/BuySharesDetail/BuySharesDetail';
const { MyDeal } = lazyImport(() => import('./routes/MyDeal/MyDeal'), 'MyDeal');
const { Contracts } = lazyImport(() => import('./routes/Contract/Contracts'), 'Contracts');
const { ContractSignin } = lazyImport(
  () => import('./routes/Contract/ContractSignin'),
  'ContractSignin'
);
const { PaymentContract } = lazyImport(
  () => import('./routes/Contract/PaymentContract'),
  'PaymentContract'
);
const { Purchase } = lazyImport(() => import('./routes/Contract/Purchase'), 'Purchase');
const { MyLeads } = lazyImport(() => import('./routes/MyLeads/MyLeads'), 'MyLeads');
const { PropertyOption } = lazyImport(
  () => import('./routes/PropertySaleRent/PropertyOption'),
  'PropertyOption'
);
const { ReeipoServices } = lazyImport(
  () => import('./routes/ReeipoServices/ReeipoServices'),
  'ReeipoServices'
);
const { AddPropertyRent } = lazyImport(
  () => import('./routes/PropertySaleRent/AddPropertyRent'),
  'AddPropertyRent'
);
const { CompanyInvestment } = lazyImport(
  () => import('./routes/Investor/CompanyInvestment'),
  'CompanyInvestment'
);
const { OfflinePayment } = lazyImport(
  () => import('./routes/Contract/OfflinePayment'),
  'OfflinePayment'
);
const { VerifyContract } = lazyImport(
  () => import('./routes/Contract/VerifyContract'),
  'VerifyContract'
);
const { DraftListing } = lazyImport(() => import('./routes/DraftListing'), 'DraftListing');
const { PaymentDone } = lazyImport(() => import('./routes/PaymentDone/PaymentDone'), 'PaymentDone');
const { SavedSearch } = lazyImport(() => import('./routes/SavedSearch'), 'SavedSearch');
const { BusinessMeetingSent } = lazyImport(
  () => import('./routes/BusinessMetingSent/BusinessMeetingSent'),
  'BusinessMeetingSent'
);
const { AgencyMeeting } = lazyImport(
  () => import('./routes/AgencyMeeting/AgencyMeeting'),
  'AgencyMeeting'
);
const { SalePaymentContract } = lazyImport(
  () => import('../misc/routes/SaleDetail/SalePayment/SalePaymentContract'),
  'SalePaymentContract'
);
const { SaleOfflinePayment } = lazyImport(
  () => import('../misc/routes/SaleDetail/SalePayment/SaleOfflinePayment'),
  'SaleOfflinePayment'
);
const { SaleVerifyContract } = lazyImport(
  () => import('../misc/routes/SaleDetail/SalePayment/SaleVerifyContract'),
  'SaleVerifyContract'
);
const { SubscriptionPayment } = lazyImport(
  () => import('./SubscriptionType/SubscriptionPayment'),
  'SubscriptionPayment'
);
const { BuySharesDetail } = lazyImport(
  () => import('./routes/BuySharesDetail/BuySharesDetail'),
  'BuySharesDetail'
);
const { EditPropertyProject } = lazyImport(
  () => import('@/features/user/routes'),
  'EditPropertyProject'
);
const { CompleteProfile } = lazyImport(() => import('@/features/user/routes'), 'CompleteProfile');
const { FavouriteListing } = lazyImport(() => import('@/features/user/routes'), 'FavouriteListing');
const { CreateBusiness } = lazyImport(() => import('@/features/user/routes'), 'CreateBusiness');
const { HouseRentForm } = lazyImport(() => import('@/features/user/routes'), 'HouseRentForm');
const { AddList } = lazyImport(() => import('@/features/user/routes'), 'AddList');
const { AddRentSale } = lazyImport(() => import('@/features/user/routes'), 'AddRentSale');
const { CreatePropertyProject } = lazyImport(
  () => import('@/features/user/routes'),
  'CreatePropertyProject'
);
const { Profile } = lazyImport(() => import('@/features/user/routes'), 'Profile');
const { UpdateProfile } = lazyImport(() => import('@/features/user/routes'), 'UpdateProfile');
const { MyListing } = lazyImport(() => import('@/features/user/routes'), 'MyListing');
const { Option } = lazyImport(() => import('@/features/user/routes'), 'Option');
const { ProjectMoney } = lazyImport(() => import('@/features/user/routes'), 'ProjectMoney');
const { AddCompany } = lazyImport(() => import('@/features/company'), 'AddCompany');
// const { CompanyMoney } = lazyImport(() => import('@/features/user/routes'), 'CompanyMoney');
// import { EditHouseDetail } from './routes/House/EditHouseDetail';
// import { CompanyMoney } from './routes/Investor/CompanyMoney';
// import PhoneVerify from './components/PhoneVerify';
// const { EditBussinessSale } = lazyImport(
//   () => import('@/features/user/routes'),
//   'EditBussinessSale'
// );
const App = () => {
  return (
    <div>
      <Suspense
        fallback={
          <div className="w-screen h-screen alignmentLogo">
            <img src={logo} alt="pics" />
          </div>
        }
      >
        <Header type={HeaderType.LANDING} />
        <Outlet></Outlet>
      </Suspense>
    </div>
  );
};

export const userRoutes = [
  {
    path: '/user',
    element: <App />,
    children: [
      { path: '', element: <LinkNotFound /> },
      { path: 'profile', element: <Profile /> },
      { path: 'get-verified', element: <GetVerified /> },
      { path: 'get-verified-otp', element: <GetVerifiedOtp /> },
      { path: 'update-profile', element: <UpdateProfile /> },
      { path: 'my-deals', element: <MyDeal /> },
      { path: 'my-leads', element: <MyLeads /> },
      { path: 'payment-completed', element: <PaymentDone /> },
      { path: 'contract', element: <Contracts /> },
      { path: 'contract-signin', element: <ContractSignin /> },
      { path: 'favourite-list', element: <FavouriteListing /> },
      { path: 'subscription-payment', element: <ChargePayment /> },
      { path: 'subscription-select-payment/:idSub', element: <SubscriptionPayment /> },
      { path: 'subscription-payment/:idSub', element: <ChargePayment /> },
      { path: 'online-payment', element: <PaymentContract /> },
      { path: 'offline-payment', element: <OfflinePayment /> },
      { path: 'verify-contract', element: <VerifyContract /> },
      { path: 'online-business-payment', element: <SalePaymentContract /> },
      { path: 'offline-business-payment', element: <SaleOfflinePayment /> },
      { path: 'verify-business-contract', element: <SaleVerifyContract /> },
      { path: 'complete-profile', element: <CompleteProfile /> },
      { path: 'purchase', element: <Purchase /> },
      { path: 'edit-property-project', element: <EditPropertyProject /> },
      { path: 'edit-property-project/:id/', element: <EditPropertyProject /> },
      { path: 'create-business', element: <CreateBusiness /> },
      { path: 'create-business/:id/', element: <CreateBusiness /> },
      { path: 'create-house-rent', element: <HouseRentForm /> },
      { path: 'create-house-rent/:id/', element: <HouseRentForm /> },
      { path: 'find-investor', element: <Option /> },
      { path: 'select-property', element: <PropertyOption /> },
      { path: 'add-reeipo-services', element: <ReeipoServices /> },
      { path: 'project-money', element: <ProjectMoney /> },
      { path: 'project-money/:id/', element: <ProjectMoney /> },
      { path: 'company-money', element: <CompanyInvestment /> },
      { path: 'company-money/:id/', element: <CompanyInvestment /> },
      { path: 'add', element: <AddList /> },
      { path: 'create-property-project', element: <CreatePropertyProject /> },
      { path: 'add-property-for-sale', element: <AddRentSale /> },
      { path: 'add-property-for-sale/:id', element: <AddRentSale /> },
      { path: 'add-property-for-sale/:id/', element: <AddRentSale /> },
      { path: 'add-property-for-rent', element: <AddPropertyRent /> },
      { path: 'add-property-for-rent/:id/', element: <AddPropertyRent /> },
      { path: 'my-listing', element: <MyListing /> },
      { path: 'draft-listing', element: <DraftListing /> },
      { path: 'my-bookings', element: <BusinessMeetingSent /> },
      { path: 'agency-list', element: <AgencyMeeting /> },
      { path: 'shares-list', element: <BuySharesDetail /> },
      { path: 'saved-search', element: <SavedSearch /> },
      { path: 'create-company', element: <AddCompany /> },
      { path: 'subscription', element: <SubscriptionType /> },
      { path: 'rating/:id', element: <Rating /> },
      { path: '*', element: <Navigate to="." /> },
      // { path: 'edit-business-sale/:id/', element: <EditBussinessSale /> },
      // { path: 'edit-business-sale', element: <EditBussinessSale /> },
      // { path: 'company-money', element: <CompanyMoney /> },
      // { path: 'edit-house-rent', element: <EditHouseDetail /> },
      // { path: 'edit-house-rent/:id/', element: <EditHouseDetail /> },
    ],
  },
];
