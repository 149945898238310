import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../user.css';
import { Image } from '@/components/Elements';
import business from '@/assets/business.jpeg';
// import { useHousingListing } from '@/api/housing/getHousingList';
import { Housing } from '@/types';
import { changeFav } from '@/api/housing/changeFav';
import propertyType from '@/constants/propertyType';
import { Modal } from 'react-responsive-modal';
import { Button } from '@/components/Elements/Button';
import { useNotificationStore } from '@/stores/notifications';
import { deletePropertyItem } from '@/api/deletePropertyItem';

import { changeAllFav } from '@/api/changeAllFav';
import { useAuth } from '@/lib/auth';
import { formatPrice, trimThirty } from '@/utils/format';
import storage from '@/utils/storage';
import i18next from 'i18next';

const HousingRent = ({
  item,
  headData,
  editIcon,
  deleteIcon,
  heartIcon,
  heartFavIcon,
  setHeartClicked,
  setDeleteClicked,
}: {
  deleteIcon: boolean;
  item: Housing;
  headData: boolean;
  editIcon: boolean;
  heartFavIcon: boolean;
  heartIcon: boolean;
  setHeartClicked: any;
  setDeleteClicked: any;
}) => {
  // const { refetch } = useHousingListing({ page: '1' });
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/housing-detail/${item.id}`);
  };
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  // const onOpenModal = () => setOpen(true);
  const localSymbol = storage.getSymbol();
  const localCurrency = storage.getAmount();

  const onOpenModals = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(true);
  };
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const typeVal = 'HouseRent';
  const deleleItems = async (id: string, types: string) => {
    try {
      setButtonLoading(true);
      await deletePropertyItem(id, types);
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'Item deleted successfully!',
      });
    } finally {
      setDeleteClicked(true);
      setOpen(false);
      setButtonLoading(false);
    }
  };

  const [favIcon, setFavIcon] = useState<boolean>(!!item.is_favourite);
  const deleleItemsSelect = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    deleleItems(item.id, typeVal);
  };
  const onCloseModal = () => setOpen(false);

  const changeStatusAction = async (id: string, accept: boolean) => {
    try {
      if (user) {
        await changeFav(id, accept);
        setHeartClicked(true);
      } else {
        useNotificationStore.getState().addNotification({
          title: 'Warning',
          type: 'info',
          message: 'Please Login first!',
        });
        navigate('/auth/login');
      }
    } finally {
    }
  };
  const changeUnfavStatusAction = async (id: string, type: string) => {
    try {
      await changeAllFav(id, type);
    } finally {
      setHeartClicked(true);
    }
  };
  const handleUnfavourite = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeUnfavStatusAction(item.id, item.type);
    // refetch();
  };
  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setFavIcon((val) => !val);
    changeStatusAction(item.id, false);
  };
  const handleClick2 = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeStatusAction(item.id, true);
    setFavIcon((val) => !val);
  };
  const handleEdit = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigate(`/user/create-house-rent/${item.id}?type=RentProperty`);
  };
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 home-prop-col">
        <button className="housingButton" onClick={handleNavigate}>
          <div className="item-box border p-2 position-relative">
            {headData == true ? (
              <div className="head-functions">
                <span className="status">{i18next.t('Housing For Rent')}</span>
                <div className="d-flex icons">
                  {editIcon == true ? (
                    <span>
                      <button onClick={handleEdit}>
                        <i className="fa-solid fa-pen" />
                      </button>
                    </span>
                  ) : null}
                  {deleteIcon == true ? (
                    <span>
                      <button onClick={onOpenModals}>
                        <i className="fa-solid fa-trash" />
                      </button>
                    </span>
                  ) : null}
                  {heartIcon == true ? (
                    <div className=" fav-lists">
                      {favIcon ? (
                        <button onClick={handleClick}>
                          <i className="fa-solid fa-heart"></i>
                        </button>
                      ) : (
                        <button onClick={handleClick2}>
                          <i className="fa-regular fa-heart"></i>
                        </button>
                      )}
                    </div>
                  ) : null}
                  {heartFavIcon == true ? (
                    <div className=" fav-lists">
                      <button onClick={handleUnfavourite}>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="head-functions2 ">
                {item?.is_favourite == true ? (
                  <button onClick={handleClick}>
                    <i className="fa-solid fa-heart"></i>
                  </button>
                ) : (
                  <button onClick={handleClick2}>
                    <i className="fa-regular fa-heart"></i>
                  </button>
                )}
              </div>
            )}

            <Image
              imagefallback={business}
              className="itemImage"
              alt="banner"
              src={item.image_data}
            />
            <h6 className="mt-3 text-dark">{item.first_name}</h6>
            <h6 className="mt-3 text-dark">{trimThirty(item.location)}</h6>
            <div className="tab">
              <p>
                {i18next.t('Max Monthly Rent')} {localSymbol}
              </p>
              <p>
                {localSymbol} {formatPrice(item.max_month_price * localCurrency)}
              </p>
            </div>
            <div className="tab">
              <p>{i18next.t('Property Type')}</p>

              <p>{propertyType[item?.property_type]}</p>
            </div>
            <div className="tab">
              <p>{i18next.t('Wanted From')}</p>
              <p>{item.wanted_from}</p>
            </div>
            <div className="tab">
              {item?.pet === 'no'
                ? `${i18next.t('No pet is allowed')}`
                : `${item?.no_of_resident} ${i18next.t('person with a pet')}`}
              {/* <p>Number Of Residents</p>
              <p>{item.no_of_resident}cccccc</p> */}
            </div>
          </div>
        </button>
        <Modal open={open} onClose={onCloseModal} center>
          <section className="model-datas">
            <h3>Are you sure you want to delete this item ?</h3>
          </section>
          <p className="btn-lines delete-bots">
            <Button isLoading={buttonLoading} onClick={deleleItemsSelect}>
              {i18next.t('Yes')}
            </Button>
            <Button onClick={onCloseModal}>No</Button>
          </p>
        </Modal>
      </div>
    </>
  );
};

export default HousingRent;
