import clsx from 'clsx';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

const variants = {
  primary: '',
  secondary: 'bg-button',
};

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'month' | 'year' | 'time' | 'float';
  className?: string;
  wrapperClass?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  variant?: keyof typeof variants;
  min?: number;
  disabled?: boolean;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    variant = 'primary',
    wrapperClass,
    className,
    registration,
    error,
    min,
    disabled = false,
  } = props;
  const [show, setShow] = useState(false);

  return (
    <FieldWrapper className={wrapperClass ?? ''} label={label} error={error}>
      <div className="form-floating">
        <input
          id="input"
          type={show ? 'text' : type}
          className={clsx(
            'form-control',
            error?.message && 'is-invalid',
            variants[variant],
            className
          )}
          disabled={disabled}
          min={min}
          placeholder={label}
          style={{ paddingRight: type === 'password' ? '35px' : '0.75rem' }}
          {...registration}
        />
        <label htmlFor="input">{label}</label>
      </div>

      {type === 'password' && (
        <span
          role="button"
          tabIndex={-1}
          onKeyDown={() => setShow(!show)}
          onClick={() => setShow(!show)}
          className={clsx('passwordIcon')}
        >
          {!show ? <i className="fa-regular fa-eye" /> : <i className="fa-regular fa-eye-slash" />}
        </span>
      )}
    </FieldWrapper>
  );
};
