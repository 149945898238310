import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type RadioButtonFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  value?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const RadioButtonField = (props: RadioButtonFieldProps) => {
  const { label, value = '', className, registration, error } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className="form-check">
        <input
          {...registration}
          className={clsx(className, 'form-check-input')}
          type="radio"
          value={value}
          id={`${value}id` ?? 'flexCheckDefault'}
        />
        <label className="form-check-label" htmlFor={`${value}id` ?? 'flexCheckDefault'}>
          {label}
        </label>
      </div>
    </FieldWrapper>
  );
};
