import React, { useState, useEffect } from 'react';
import * as z from 'zod';
import { useParams } from 'react-router-dom';
import PropertyDetail from '@/components/Elements/Loader/PropertyDetail';
import { usePropertyForRentDetails } from '@/api/propertyForRent/getPropertyforRentDetails';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GoogleMapReact from 'google-map-react';
import { Form, InputField, TextAreaField } from '@/components/Form';
import { Button } from '@/components/Elements';
import { inquiryPropertyForRent } from '@/api/propertyForRent/inquiryPropertyForRent';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';
import 'react-responsive-tabs/styles.css';
import './Carousel.css';
import icon1 from '@/assets/icon1.png';
import icon2 from '@/assets/icon2.png';
import icon3 from '@/assets/icon3.png';
import clsx from 'clsx';
import './PropertyRent.css';
import { InputPhone } from '@/components/Form/InputPhone';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';
import FsLightbox from 'fslightbox-react';
import storage from '@/utils/storage';
import i18next from 'i18next';

function PropertyContentRent() {
  const localCurrency = storage.getAmount();
  const localSymbol = storage.getSymbol();
  const [phone, setPhone] = useState<boolean>(false);
  const [toggler, setToggler] = useState<boolean>(false);
  const [pics, setPics] = useState([]);
  const [productIndex, setProductIndex] = useState(0);

  const AnyReactComponent = ({ text }: { text: any }) => <div>{text}</div>;
  const auth = useAuth();
  const loggedinId = auth.user?.customer_id;

  const { id } = useParams();
  const { data, isLoading } = usePropertyForRentDetails({ id: id ?? '2' });

  const customerId = data?.data?.customer_id;
  const property = data?.data;
  const [iLat, setiLat] = useState(0);
  const [iLong, setiLong] = useState(0);
  useEffect(() => {
    if (property) {
      const parsedLat = parseFloat(property.latitude);
      const parsedLong = parseFloat(property.longitude);

      if (!isNaN(parsedLat) && !isNaN(parsedLong)) {
        setiLat(parsedLat);
        setiLong(parsedLong);
      } else {
        console.error('Invalid latitude or longitude values');
      }
    }
  }, [property]);
  const amenitiesValues = property?.facilites ?? '';
  const amenitiesArray = amenitiesValues.split(',');

  const schema = z.object({
    telephone: z.custom(isValidMobile, isValidMobileMsg),

    message: z.string().min(1, 'Please enter a message'),
    email: z
      .string()
      .min(1, 'Please enter email address')
      .email('Please enter valid email address!'),
  });

  type inquiryAd = {
    telephone: string;
    message: string;
    email: string;
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: inquiryAd) => {
    try {
      setLoading(true);

      await inquiryPropertyForRent({
        ...values,
        id: `${id}`,
        type: `RentProperty`,
        project_id: `${id}`,
      });
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'info',
        message: 'Inquiry Sent successfully!',
      });
    } finally {
      setLoading(false);
      localStorage.removeItem('values');
    }
  };

  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<', '>'],
    rewind: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<', '>'],
    rewind: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    eval(property?.image_data)?.map((val) => {
      setPics((item) => [...item, val?.image]);
    });
  }, [isLoading, loading]);

  const handleToggler = (index: number) => {
    setToggler((val) => !val);
    setProductIndex(index);
  };

  return (
    <>
      {isLoading || loading ? (
        <PropertyDetail />
      ) : (
        <div className="Content">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                {eval(property?.image_data).length == 0 ? null : (
                  <>
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <div className="propertyCarousel  noter">
                          <OwlCarousel {...options} className="owl-theme" loop nav>
                            {eval(property?.image_data).map((item: any, index: number) => (
                              <div key={index}>
                                <div className="item">
                                  {item?.name && <span className="imageNames">{item?.name}</span>}
                                  <img alt="hias" src={item?.image} />
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        </div>
                      </div>

                      <div className="col-md-12 col-12 row picsfor rent-slide">
                        <OwlCarousel {...options2} className="owl-theme" loop nav>
                          {eval(property?.image_data).map((item: any, index: number) => (
                            <div key={index}>
                              <div className="item">
                                {item?.name && <span className="imageNames">{item?.name}</span>}
                                <img
                                  alt="hias"
                                  src={item?.image}
                                  style={{ maxHeight: '200px', objectFit: 'cover' }}
                                  onClick={() => handleToggler(index)}
                                />
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                      <FsLightbox toggler={toggler} sources={pics} sourceIndex={productIndex} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <section className="rent-prop-row bg-light-pink mt-5 propertyName main-prop-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 left-columns pb-5">
                  <div>
                    <span className="forR">{i18next.t('For Rent')}</span>
                    <h4 className="propTitle mb-2">{property?.title}</h4>
                    <div className="facilities mb-4 rent-faclity">
                      <div>
                        <img src={icon1} alt="pay1" />
                        <span>
                          {' '}
                          {property?.bathrooms} {i18next.t('Baths')}
                        </span>
                      </div>
                      <div>
                        <img src={icon2} alt="pay1" />
                        <span>
                          {' '}
                          {property?.bedrooms} {i18next.t('Beds')}
                        </span>
                      </div>
                      <div>
                        <img src={icon3} alt="pay1" />
                        <span>
                          {property?.size} {i18next.t('Sq.Ft')}.
                        </span>
                      </div>
                    </div>
                    <p>{property?.description}</p>
                    <h3 className="money">
                      {localSymbol} {(property?.monthly_rent * localCurrency)?.toFixed(0)}
                    </h3>
                    <i className="fa-solid fa-location-dot"></i>
                    <span className="ms-2">
                      {property?.country} , {property?.state} , {property?.city}
                    </span>
                    <div className="descri">
                      <h6>{i18next.t('About the property')}</h6>
                      <div className="adrs house-pr-add pt-0">
                        <span>
                          <i className="fa-solid fa-map-pin"></i>{' '}
                          <span>
                            {i18next.t('Energy Rating')} : {property?.energy_rating}
                          </span>
                        </span>
                        <span>
                          <i className="fa-solid fa-location-dot"></i> {i18next.t('Heating Grade')}{' '}
                          :{property?.heating_grade}
                        </span>
                        <span>
                          <i className="fa-solid fa-building"></i>
                          {i18next.t('Building floor')}: {property?.floor}
                        </span>
                        <span>
                          <i className="fa-solid fa-building"></i>{' '}
                          <span>
                            {i18next.t('Rented dates')} : {property?.rented_from} to{' '}
                            {property?.rented_to}
                          </span>
                        </span>
                      </div>
                      <hr />
                      <h6>{i18next.t('Amenities')}</h6>
                      <div className="amenitiesDiv">
                        <div>
                          <div className="place-offers-maincol rent-view">
                            <span>
                              {amenitiesArray.includes('Garden_view') ? (
                                <>
                                  <i className="fa-solid fa-tree"></i>
                                  {i18next.t('Garden View')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('Kitchen') ? (
                                <>
                                  <i className="fa-solid fa-kitchen-set"></i> {i18next.t('Kitchen')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('Linens') ? (
                                <>
                                  <i className="fa-solid fa-shirt"></i> {i18next.t('Linens')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('tv') ? (
                                <>
                                  <i className="fa-solid fa-tv"></i> {i18next.t('Telivision')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('Extra_Chairs') ? (
                                <>
                                  <i className="fa-solid fa-chair"></i> {i18next.t('Extra Chair')}
                                </>
                              ) : null}
                            </span>

                            <span>
                              {' '}
                              {amenitiesArray.includes('Charger') ? (
                                <>
                                  <i className="fa-solid fa-bolt"></i> {i18next.t('Charger')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('Candels') ? (
                                <>
                                  <i className="fa-solid fa-hanukiah"></i> {i18next.t('Candels')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('Utensils') ? (
                                <>
                                  <i className="fa-solid fa-utensils"></i> {i18next.t('Utensils')}
                                </>
                              ) : null}
                            </span>

                            <span>
                              {' '}
                              {amenitiesArray.includes('Bath_Tub') ? (
                                <>
                                  <i className="fa-solid fa-bath"></i> {i18next.t('Bath Tub')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {amenitiesArray.includes('Hair_Chair') ? (
                                <>
                                  <i className="fa-solid fa-couch"></i> {i18next.t('Couch')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {' '}
                              {amenitiesArray.includes('Window_ac_unit') ? (
                                <>
                                  <i className="fa-solid fa-temperature-low"></i>{' '}
                                  {i18next.t('Window Ac Unit')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {' '}
                              {amenitiesArray.includes('Pets_Allowed') ? (
                                <>
                                  <i className="fa-solid fa-dog"></i> {i18next.t('Pets Allowed')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {' '}
                              {amenitiesArray.includes('Dedicated_Workspace') ? (
                                <>
                                  <i className="fa-solid fa-briefcase"></i>{' '}
                                  {i18next.t('Dedicated Work Space')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {' '}
                              {amenitiesArray.includes(' Extra_Pillows_And_Blankets') ? (
                                <>
                                  <i className="fa-solid fa-mattress-pillow"></i>{' '}
                                  {i18next.t('Extra Pillow And Blanket')}
                                </>
                              ) : null}
                            </span>
                            <span>
                              {' '}
                              {amenitiesArray.includes('Extra_bed') ? (
                                <>
                                  <i className="fa-solid fa-bed"></i> {i18next.t('Extra Bed')}
                                </>
                              ) : null}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h6>{i18next.t('Basic Details')}</h6>
                      <div className="rsmain row">
                        <div className="col-md-3 col-12 no-space">
                          <div className="rsInner">
                            <p className="toprs">{i18next.t('Postal Code')}</p>
                            <p className="bottomrs">{property?.postal_code}</p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 no-space">
                          <div className="rsInner">
                            <p className="toprs">{i18next.t('Include in Rent')}</p>
                            <p className="bottomrs">
                              {localSymbol}{' '}
                              {(property?.included_in_rent * localCurrency)?.toFixed(0)}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 no-space">
                          <div className="rsInner">
                            <p className="toprs">{i18next.t('Monthly rent')}</p>
                            <p className="bottomrs">
                              {localSymbol} {(property?.monthly_rent * localCurrency)?.toFixed(0)}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 no-space">
                          <div className="rsInner">
                            <p className="toprs">{i18next.t('Security Money')}</p>
                            <p className="bottomrs">
                              {localSymbol} {(property?.security_money * localCurrency)?.toFixed(0)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="rent-prop-map mt-5">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div style={{ height: '300px', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                      defaultCenter={{ lat: iLat, lng: iLong }}
                      defaultZoom={13}
                    >
                      <AnyReactComponent
                        lat={iLat}
                        lng={iLong}
                        text={<i className="fa-sharp fa-solid fa-location-dot googleEarth"></i>}
                      />
                    </GoogleMapReact>
                  </div>{' '}
                </div>
                <div className="col-12 col-md-6">
                  <div className="main-datas propert-details d-block">
                    <div>
                      <span className="titleHeader2"> {i18next.t('Inquiry about the ad')}</span>
                      <div className="cardss iqforms">
                        <Button
                          onClick={() => setPhone(true)}
                          className={clsx(phone == true ? 'clearBack' : 'pinkBack')}
                        >
                          {phone ? (
                            <>
                              <span className="hhh">
                                {' '}
                                <i className="fa-solid fa-phone-volume"></i> {property?.telephone}
                              </span>
                            </>
                          ) : (
                            <>
                              <i className="fa-solid fa-phone-volume"></i>{' '}
                              {i18next.t('Show Phone Number')}
                            </>
                          )}
                        </Button>

                        <div className="inquireformhover">
                          <fieldset disabled={loggedinId === customerId}>
                            <Form<inquiryAd>
                              onSubmit={handleSubmit}
                              schema={schema}
                              className="formMsg"
                            >
                              {({ register, formState }) => (
                                <>
                                  <InputPhone
                                    floating
                                    error={formState.errors['telephone']}
                                    registration={register('telephone')}
                                  />
                                  <InputField
                                    label={i18next.t('Email')}
                                    error={formState.errors['email']}
                                    registration={register('email')}
                                  />
                                  <TextAreaField
                                    label={i18next.t('Message')}
                                    error={formState.errors['message']}
                                    registration={register('message')}
                                  />
                                  {loggedinId === customerId && (
                                    <p className="message">You are owner of this Property</p>
                                  )}
                                  <div className="iq-div">
                                    <Button type="submit" className="iq-btn">
                                      {i18next.t('Inquire Now')}
                                    </Button>
                                  </div>
                                </>
                              )}
                            </Form>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="row col-12 propertyName"></div>
        </div>
      )}
    </>
  );
}

export default PropertyContentRent;
