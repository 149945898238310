import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type CheckBoxFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  value?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const CheckBoxField = (props: CheckBoxFieldProps) => {
  const { label, value = '', className, registration, error } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className="form-check">
        <input
          {...registration}
          className={clsx(className, 'form-check-input')}
          type="checkbox"
          value=""
          id={`${value}id` ?? 'flexCheckDefault'}
        />
        <label className="form-check-label" htmlFor={`${value}id` ?? 'flexCheckDefault'}>
          {label}
        </label>
      </div>
    </FieldWrapper>
  );
};
